import React from "react";

import Card1plg from "../../assets/images/Card1plg.svg";
import Card2plg from "../../assets/images/Card2plg.svg";
import Card3plg from "../../assets/images/Card3plg.svg";
import Card4plg from "../../assets/images/Card4plg.svg";
import Card5plg from "../../assets/images/Card5plg.svg";

export default function Upfront() {
  return (
    <div>
      <div className="w-11/12 sm:max-w-[1170px] mx-auto pt-10 sm:pt-[70px] md:pt-[141px] bg-white">
        <div className=" sticky top-32 w-full md:w-[680px] mx-auto">
          <h1 className="f-f-b-g text-3xl sm:text-6xl  text-primary leading-10 sm:leading-[56px] text-center">
            Simple. Hassle-free
          </h1>
          <h1 className="f-f-b-g text-3xl sm:text-6xl lg:text-7xl text-black leading-10 sm:leading-[56px] text-center">
            Here’s how it works
          </h1>
        </div>
        <div className=" sticky top-[240px] bg-card1 mt-[93px] w-full rounded-2xl h-auto lg:h-[440px] p-8 sm:p-10">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 lg:col-span-7">
              <div className="lg:pl-20 pl-2 w-full lg:w-[82%] text-center lg:text-start">
                <h1 className="f-f-b text-base text-primary">Step 1</h1>
                <h2 className="f-f-b-g text-[20px] sm:text-xl xl:text-4xl text-black mt-6 sm:mt-8 xl:leading-10 ">
                  Enable SaaSPay on your existing checkout system or explore our
                  native integration
                </h2>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <img
                src={Card1plg}
                className=" lg:ml-auto w-full h-[250px] sm:h-[304px] mt-7 "
              />
            </div>
          </div>
        </div>
        <div className=" sticky top-[240px] bg-card2 mt-8 w-full rounded-2xl h-auto lg:h-[440px] p-8 sm:p-10">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 lg:col-span-7">
              <div className="lg:pl-20 pl-2 w-full lg:w-[77%] text-center lg:text-start">
                <h1 className="f-f-b text-base text-purple-vlight">Step 2</h1>
                <h2 className="f-f-b-g text-[20px] sm:text-xl xl:text-4xl text-black mt-6 sm:mt-8 xl:leading-10 ">
                  SaaSPay acts as a payment flexibility layer on top of annual
                  subscription
                </h2>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <img
                src={Card2plg}
                className=" lg:ml-auto w-full h-[250px] sm:h-[304px] mt-7 "
              />
            </div>
          </div>
        </div>
        <div className=" sticky top-[240px] bg-card3 mt-8 w-full rounded-2xl h-auto lg:h-[440px] p-8 sm:p-10">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 lg:col-span-7">
              <div className="lg:pl-20 pl-2 w-full lg:w-[79%] text-center lg:text-start ">
                <h1 className="f-f-b text-base text-skyblue-vlight">Step 3</h1>
                <h2 className="f-f-b-g text-[20px] sm:text-xl xl:text-4xl text-black mt-6 sm:mt-8 xl:leading-10 ">
                  Customers choose to BNPL at the checkout
                </h2>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <img
                src={Card3plg}
                className=" lg:ml-auto w-full h-[250px] sm:h-[304px] mt-7 "
              />
            </div>
          </div>
        </div>

        <div className=" sticky top-[240px] bg-card4 mt-8 w-full rounded-2xl h-auto lg:h-[440px] p-8 sm:p-10">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 lg:col-span-7">
              <div className="lg:pl-20 pl-2 w-full lg:w-[75%] text-center lg:text-start">
                <h1 className="f-f-b text-base text-yellow-dark">Step 4</h1>
                <h2 className="f-f-b-g text-[20px] sm:text-xl xl:text-4xl text-black mt-6 sm:mt-8 xl:leading-10 ">
                  They pay monthly through E-Nach & enjoy annual discounts
                </h2>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <img
                src={Card4plg}
                className=" lg:ml-auto w-full h-[250px] sm:h-[304px] mt-7 "
              />
            </div>
          </div>
        </div>
        <div className=" sticky top-[240px] bg-card5 mt-8 w-full rounded-2xl h-auto lg:h-[440px] p-8 sm:p-10">
          <div className="grid grid-cols-12 items-center">
            <div className="col-span-12 lg:col-span-7">
              <div className="lg:pl-20 pl-2 w-full lg:w-[75%] text-center lg:text-start ">
                <h1 className="f-f-b text-base text-[#FF9793]">Step 5</h1>
                <h2 className="f-f-b-g text-[20px] sm:text-xl xl:text-4xl text-black mt-6 sm:mt-8 xl:leading-10 ">
                  You earn ACV on Day 1 of the subscription
                </h2>
              </div>
            </div>
            <div className="col-span-12 lg:col-span-5">
              <img
                src={Card5plg}
                className="lg:ml-auto w-full h-[250px] sm:h-[304px] mt-7 "
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
