import React from "react";
import { Switch } from "@material-tailwind/react";

import Heroimg from "../../assets/images/Group 1171280887.png";

export default function Hero() {
  return (
    <div>
      <div className=" w-11/12 2xl:max-w-[1240px] pt-32 mx-auto ">
        <div className=" grid grid-cols-12 items-center ">
          <div className="  col-span-12 xl:col-span-7 2xl:col-span-7 ">
            <div className=" pt-[30px] sm:pt-[72px] md:pt-[104px] pb-[50px] xl:pb-[181px] ">
              <Switch color="green" checked />
              <h4 className=" text-5xl sm:text-8xl  2xl:text-[64px] f-f-b-g text-white  sm:leading-[72px]  ">
                <span className="text-green "> Toggle On: </span> Enable SaaS &
                Cloud BNPL on your Payment Gateway
              </h4>
              <h5 className=" f-f-m text-lg  2xl:text-[20px]  text-grey mt-[14px] ">
                To realise Annual Contract Value on Day 1, even on monthly
                billable
              </h5>
              <button
                class="  bg-primary w-[251px] h-[64px] rounded-[48px] f-f-b text-lg text-white mt-[68px] "
                onclick={() =>
                  window.open(
                    "https://calendly.com/mukund-saas/get-to-know-saaspay?month=2024-04"
                  )
                }
              >
                Schedule a Demo
              </button>
            </div>
          </div>
          <div className="  col-span-12 xl:col-span-5 2xl:col-span-4 ">
            <img
              src={Heroimg}
              className=" object-contain  w-full 2xl:w-[587px] h-[549px] sm:mb-[42px] sm:mt-[60px] lg:mt-0 2xl:absolute 2xl:top-[140px]  2xl:right-0 "
            />
          </div>
        </div>
      </div>
    </div>
  );
}
