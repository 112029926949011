import React from "react";

import Navbar from "../../organisms/Layout/Navbar";
import Hero from "./Hero";
import Seller from "../BuyingOnAws/Seller";
import Faq from "../../molecules/Faq";
import Footer from "../../organisms/Layout/Footer";
import Services from "./Services";
import Upfront from "./Upfront";
import Sassales from "./Sassales";

export const FaqData = [
  {
    heading:
      "What is a SaaS payment checkout and how can it benefit my business?",
    content:
      "A SaaS payment checkout is a payment flexibility provider that allows businesses to receive payments from their customers. It benefits your business by consolidating monthly payments into annual payouts, enhancing cash flow, and reducing payment-related challenges.",
  },
  {
    heading:
      "What payment methods can I offer through a SaaS payment solution?",
    content:
      "AWS Marketplace is a digital catalog of software solutions, services, and products that are pre-configured for use on the Amazon Web Services (AWS) cloud platform. It offers a wide range of offerings from independent software vendors (ISVs) and service providers, making it easier for businesses to find, purchase, and deploy software and services directly within their AWS environment.",
  },
  {
    heading:
      "What are the benefits of BNPL integrating payment solutions into my <br/> business operations?",
    content:
      "Pay Later on AWS allows you to use native services and pay back on your own terms! This option provides flexibility in managing your cash flow, enabling you to use AWS services immediately while deferring payment for a more convenient time. It simplifies billing and can be a valuable resource for businesses looking to optimize their financial operations.",
  },
  {
    heading: "How do I choose the right SaaS payment gateway for my business?",
    content:
      "Net 90 payment terms mean that you have 90 days from the date of purchase to make the payment for the AWS services you've used. This extended payment period helps you improve cash flow management by delaying the settlement of charges. It offers financial flexibility and allows you to invest in your business without immediate payment constraints.",
  },
  {
    heading:
      "How does SaaSPay's BNPL integration benefit my customers during <br/> checkout?",
    content:
      "With the Flexible Payment Options, you can now choose from various payment methods, including Pay Later and Net 90, to match your financial needs. There is not any rigidity involved anymore, so you grow on your terms while enjoying the benefits of your favorite SaaS solutions.",
  },
  {
    heading: "Is BNPL integration secure for both my business and customers?",
    content:
      "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
  },
  {
    heading: "Will integrating BNPL increase conversion rates?",
    content:
      "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
  },
  {
    heading: "Does SaaSPay's BNPL product work on credit cards?",
    content:
      "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
  },
  {
    heading: "How does BNPL integration affect my cash flow?",
    content:
      "The 90-day invoice terms refer to the payment terms available in the AWS Marketplace, specifically in the Net 90 payment option. With this, you have 90 days to settle the invoice for AWS services you've used. This extended period provides greater flexibility for managing your budget and cash flow while still benefiting from AWS's cloud services.",
  },
];
export default function Plg() {
  return (
    <div>
      <Navbar dark />
      <div className=" bg-primary-vdark  ">
        <Hero />
      </div>
      <Seller />
      <Services />
      <Upfront />
      <Sassales />
      <Faq data={FaqData} />
      <Footer />
    </div>
  );
}
