import React from "react";
import Plgpage from "../components/PLG/index";
import { PageSEO } from "../atoms/SEO";

export default function plg({ location }) {
  return (
    <div>
      <PageSEO
        path={location.pathname}
        title={"SaaSPay's B2B Buy-Now-Pay-Later payment checkout solution"}
        description={
          "Provide your B2B customers a fast and easy checkout option to manage all SaaS and cloud expenses with SaaSPay's Buy-Now-Pay-Later solution."
        }
      />
      <Plgpage />
    </div>
  );
}
