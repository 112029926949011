import React from "react";

import Circle from "../../assets/images/Group 1171280966.svg";
import Serviceimg from "../../assets/images/Frame 1171280884.svg";
import Circle2 from "../../assets/images/Group 1171280967.svg";

export default function Services() {
  return (
    <div>
      <div className="w-11/12 sm:max-w-[1170px] mx-auto pt-[45px] sm:pt-[72px] md:pt-[135px] bg-white  ">
        <div className="w-full lg:w-[890px] lg:mx-auto text-center ">
          <h1 className="f-f-b-g text-2xl sm:text-3xl md:text-[38px] lg:text-7xl text-black lg:text-center md:leading-[56px]">
            Integrate <span className="  text-primary "> Effortlessly </span>{" "}
            with your Existing Checkout Solution
          </h1>
          <div className=" lg:w-[726px] mx-auto text-center ">
            <p className=" text-primary-light  f-f-m text-lg leading-[32px] mt-[32px]  ">
              A payment flexibility layer on annual subscriptions, this seamless
              addition enables customers to enjoy discounts without upfront
              payment, while you effortlessly realize the forecasted revenue of
              monthly paying customers
            </p>
          </div>
        </div>
        <div className="grid grid-cols-12 mt-[50px] sm:mt-[72px] lg:gap-[79px]">
          <div className="col-span-12 xl:col-span-6">
            <div className="inline-flex  space-x-5 mt-[13px]">
              <img src={Circle} className="w-12 h-12 mt-3" alt="" />
              <div className="">
                <h1 className="f-f-b-g text-xl lg:text-2xl text-black sm:leading-9">
                  Introduce BNPL for Faster Conversions
                </h1>
                <p className="f-f-r text-base text-grey-dark mt-[6px]">
                  With the BNPL (Buy Now, Pay Later) option right on your
                  checkout screen, SaaSPay enables faster and more effective
                  conversions. This feature not only enhances the customer
                  experience but also significantly boosts your sales conversion
                  rates.
                </p>
              </div>
            </div>
            <div className="w-full h-[2px] bg-grey-dark mt-8 opacity-[0.1]  "></div>

            <div className="inline-flex  space-x-5 mt-8">
              <img src={Circle} className="w-12 h-12 mt-3" alt="" />
              <div className="">
                <h1 className="f-f-b-g text-xl lg:text-2xl text-black leading-9">
                  Eliminate Churn caused by Credit Card Auto-debit Failure
                </h1>
                <p className="f-f-r text-base text-grey-dark mt-[6px]">
                  An avg churn of 34% is observed due to auto-debit failure on
                  credit cards. Eliminate this by introducing SaaSPay, where you
                  enable monthly payment flexibility for customers supported by
                  enach & earn ACV on day 1 of the subscription.
                </p>
              </div>
            </div>
            <div className="w-full h-[2px] bg-grey-dark mt-8 opacity-[0.1]"></div>

            <div className="inline-flex  space-x-5 mt-8">
              <img src={Circle2} className="w-12 h-12 mt-3" alt="" />
              <div className="">
                <h1 className="f-f-b-g text-xl lg:text-2xl text-black leading-9">
                  Eliminate Payment Chasing to Increase CLTV
                </h1>
                <p className="f-f-r text-base text-grey-dark mt-[6px]">
                  Say goodbye to the hassle of payment chasing. SaaSPay's
                  integration eliminates payment follow-ups to improve customer
                  lifetime and satisfaction by 12x. They earn discounts without
                  upfront payments & through E-Nach you facilitate a seamless
                  payment experience.
                </p>
              </div>
            </div>
          </div>

          <div className="col-span-12 xl:col-span-6">
            <img
              src={Serviceimg}
              className="w-full h-auto xl:h-[721px] mt-10 lg:mt-0"
              alt=""
            />
          </div>
        </div>
      </div>
    </div>
  );
}
