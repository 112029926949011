import React from "react";

export default function Sassales() {
  return (
    <div className=" w-11/12 2xl:max-w-[1170px] 3xl:max-w-[1500px] mx-auto  pt-10 sm:pt-[70px] md:pt-[141px] ">
      <div className="  liese-bg p-[32px] h-auto ">
        <div className="grid grid-cols-12  gap-4 sm:gap-8 items-center">
          <div className=" col-span-12 xl:col-span-10">
            <h2 className="  f-f-b-g text-3xl md:text-[34px] leading-[36px] text-white  text-center xl:text-left ">
              Embrace the Future of SaaS Payments with SaaSPay
            </h2>
          </div>
          <div className="col-span-12 xl:col-span-2 text-center xl:text-right ">
            <button
              className="w-[151px] h-[64px]  rounded-[48px] bg-white text-green-light text-lg f-f-b "
              onclick={() =>
                window.open("https://form.jotform.com/240982279984475")
              }
            >
              Book a Demo
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
